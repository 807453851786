import Parallax from "parallax-js";

$(document).on("turbolinks:load", function() {
  var scene = $("#parallaxScene").get(0);
  if (!!scene) {
    var parallaxInstance = new Parallax(scene);
    parallaxInstance;
    $("#parallaxScene, [data-paroller-factor]").paroller({
      factor: 0.05, // multiplier for scrolling speed and offset
      factorXs: 0.1, // multiplier for scrolling speed and offset
      type: "foreground", // background, foreground
      direction: "vertical" // vertical, horizontal
    });
  }
});
